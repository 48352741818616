// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true /* Do not change*/,
  securedGraphQlGateway: 'https://gateway-private.dev.o8cares.com/federated-gql',
  securedApiGateway: 'https://gateway-private.dev.o8cares.com/api',
  salesForceBaseUrl: 'https://origin8cares--uat.sandbox.lightning.force.com',
  pathToSource: 'browser',
  trackingId: 'bvowph/arc',
  auth: { domain: 'auth.uat.o8cares.com', clientId: 'qZmk1Ic4jI6zNJvr3LsOVEFAkfMoQzpG', audience: 'Permission API' },
  gatewayStreamingUrl: 'https://gateway-streaming.dev.o8cares.com',
};
