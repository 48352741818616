import { Component, ViewEncapsulation } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AUTH_PATH } from '@origin8-web/core-ui/login/routes';
import { OnlyPermissionsDirective } from '@origin8-web/core-ui/login/permissions';
import { CALLS_TO_REVIEW_ROUTE, ArcPagesPermissions, MANAGE_TAGS_ROUTE } from '../../../app.interface';

@Component({
  selector: 'navbar-menu-dropdown',
  templateUrl: `./navbar-menu-dropdown.html`,
  styleUrl: `./navbar-menu-dropdown.scss`,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [RouterLink, RouterLinkActive, OnlyPermissionsDirective],
})
export class NavbarMenuDropdownComponent {
  links = {
    logout: AUTH_PATH.LOGOUT,
    callsToReview: CALLS_TO_REVIEW_ROUTE.BASE,
    manageTags: MANAGE_TAGS_ROUTE.BASE,
    dashboard: '',
  };

  permissions = {
    callsToReviewPerms: ArcPagesPermissions.callsToReviewPerms,
    manageTagsPerms: ArcPagesPermissions.manageTagsPerms,
  };
}
